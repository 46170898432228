$rem-base: 16px;

$primary: #1bc6ce;
$secondary: #f6d4d2;
$tertiary: #f7ce46;
$white: rgb(255, 255, 255);
$black: #030303;
$gray: rgb(52, 58, 64);

$breakpoints: (
  xxs: 320px,
  xs: 375px,
  sm: 425px,
  md: 768px,
  lg: 1024px,
  xl: 1280px,
  xxl: 1440px,
);
