@import 'src/styles/variables';
@import 'src/styles/utils';

.logo-link {
  display: flex;
  width: px-to-rem(400px);
  height: px-to-rem(80px);

  @include set-media(sm) {
    width: px-to-rem(200px);
    height: px-to-rem(40px);
  }
}

.page-header {
  display: flex;
  justify-content: center;

  height: auto !important;

  padding-top: px-to-rem(30px) !important;
  padding-bottom: px-to-rem(20px) !important;

  background: $black !important;

  position: sticky;
  top: 0;

  @include set-media(md) {
    padding-top: px-to-rem(20px) !important;
  }

  @include set-media(sm) {
    padding-top: px-to-rem(10px) !important;
  }
}
