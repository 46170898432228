@import '~antd/dist/antd.css';

@import 'src/styles/variables';
@import 'src/styles/utils';
@import 'src/styles/atomic';

@import 'src/styles/components/svg-icon';
@import 'src/styles/components/dt-button';
@import 'src/styles/components/dt-form';

@import 'src/styles/animations/fade-in';
@import '../styles/animations/scaleSides';
@import 'swiper/swiper.min.css';

.app {
  height: 100%;
  background: $black;
}

.app-background {
  padding-bottom: 10px;

  background: $black url('../assets/gifs/triangles.gif') no-repeat bottom;
  background-size: 80% 50%;
  background-origin: content-box;

  @include set-media(sm) {
    padding-bottom: px-to-rem(150px);
    background-size: 100% 25%;
  }
}

.router-container {
  display: flex;
  flex-direction: column;
}

::-webkit-scrollbar {
  display: none;
}

#element {
  scrollbar-width: none;
}
