@import 'src/styles/variables';
@import 'src/styles/utils';

.bm-menu-wrap {
  width: 200px !important;
}

/* Individual item */
.bm-item {
  display: inline-block;
  margin-bottom: 10px;

  color: $white;

  /* Our sidebar item styling */
  text-decoration: none;

  transition: color 0.2s;
}

/* Change color on hover */
.bm-item:hover {
  color: $primary;
}

/* The rest copied directly from react-burger-menu docs */

/* Position and sizing of burger button */
.bm-burger-button {
  position: fixed;
  bottom: 10px;

  width: px-to-rem(30px);
  height: px-to-rem(25px);

  @include set-media(lg) {
    margin: px-to-rem(30px 40px);
  }

  @include set-media(md) {
    margin: px-to-rem(15px 15px);
  }

  @include set-media(sm) {
    margin: px-to-rem(5px 5px);
  }
}

/* Color/shape of burger icon bars */
.bm-burger-bars {
  background: $white;
  border-radius: px-to-rem(5px);
}

/* Position and sizing of clickable cross button */
.bm-cross-button {
  width: px-to-rem(35px);
  height: px-to-rem(35px);
}

/* Color/shape of close button cross */
.bm-cross {
  background: $white;
}

/* General sidebar styles */
.bm-menu {
  padding: 2.5em 1.5em 0;

  font-size: 1.15em;

  background: $black;
}

/* Morph shape necessary with bubble or elastic */
.bm-morph-shape {
  fill: $black;
}

/* Wrapper for item list */
.bm-item-list {
  color: $white;
}

/* Styling of overlay */
.bm-overlay {
  background: $white;
}
