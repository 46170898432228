@import 'src/styles/variables';
@import 'src/styles/utils';

.dropdown-btn {
  border: none !important;
  padding: 0 10px;
  width: 20px;
  height: 20px;
}

.ant-dropdown-menu,
.ant-dropdown-menu-submenu-title,
.ant-dropdown-item {
  border-radius: px-to-rem(15px) !important;

  & > * {
    border-radius: px-to-rem(15px) !important;
  }
}
